import React from "react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import * as Styles from "../styles/effect.module.css";
import "../styles/blog.css";
import BlogLayout from "../components/bloglayout";
import Seo from "../components/seo";

const DocPostTemplate = ({ data: { previous, next, post } }) => {
  return (
    <BlogLayout>
      <Seo title={post.title} description={post.excerpt} />

      {/* Hero Area */}

      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative max-w-6xl mx-auto ">
          <h3 className="pt-24 text-lg md:pt-12 md:text-2xl text-bluegray">
            Documentation
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            {parse(post.title)}
          </h1>
          <Link
            to="/docs/"
            className="block mt-6 text-lg font-medium md:text-xl text-bluegray"
          >
            ‹ Back to Documentation
          </Link>
        </div>

        <div className="absolute bottom-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh]"
            }
          ></div>
        </div>
      </div>

      <article
        className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-[hsla(204,26%,99%,0)] via-white text-gray-800 relative blog-post "
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="py-8">
          {!!post.content && (
            <section itemProp="articleBody" className="prose blog-post">
              {parse(post.content)}
            </section>
          )}

          <nav className="mt-16 blog-post-nav">
            <ul className="flex justify-between">
              <li className="w-1/2 pr-4">
                {previous && (
                  <Link
                    to={previous.uri}
                    rel="prev"
                    className="text-primary hover:text-primary-dark"
                  >
                    ← {parse(previous.title)}
                  </Link>
                )}
              </li>

              <li className="w-1/2 pl-4">
                {next && (
                  <Link
                    to={next.uri}
                    rel="next"
                    className="text-primary hover:text-primary-dark"
                  >
                    {parse(next.title)} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </article>
    </BlogLayout>
  );
};

export default DocPostTemplate;

export const pageQuery = graphql`
  query DocPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpDoc(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
        }
      }
    }
    previous: wpDoc(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpDoc(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
